import axios from 'axios';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

let baseUrl = process.env.VUE_APP_API_HOST;
// 创建axios实例
const requestAxios = axios.create({
  baseURL: baseUrl, // api的base_url
  timeout: 6000 // 请求超时时间
})
const router = useRouter();

// request拦截器
requestAxios.interceptors.request.use(config => {
  // 到时在这里加验证啥的
  let authToken = localStorage.getItem('os_token');
  if (authToken) {
    config.headers['Authorization'] = authToken;
  }

  config.headers['Content-Type'] = 'application/json';
  return config
}, error => {
  Promise.reject(error)
})

// respone拦截器
requestAxios.interceptors.response.use(
  response => {
    const res = response.data;
    switch(res.code){
      case 200:
        return res.data;
      case 400:
        message.error('登录失效，即将跳转！', 2)
        setTimeout(() => {
          window.location.href = '#/login'
        }, 2001);
        return res;
      case 0:
        return Promise.reject(res);
      default:
        return Promise.reject(res);
    }
  },
  /**
  * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
  * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
  */
  //     if (res.code !== 20000) {
  //       Message({
  //         message: res.message,
  //         type: 'error',
  //         duration: 5 * 1000
  //       });
  //       // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
  //       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
  //         MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
  //           confirmButtonText: '重新登录',
  //           cancelButtonText: '取消',
  //           type: 'warning'
  //         }).then(() => {
  //           store.dispatch('FedLogOut').then(() => {
  //             location.reload();// 为了重新实例化vue-router对象 避免bug
  //           });
  //         })
  //       }
  //       return Promise.reject('error');
  //     } else {
  //       return response.data;
  //     }
  error => {
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  })

export default requestAxios