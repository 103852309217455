import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      // redirect: '/home',
      name: '欢迎页',
      component: () => import('../views/welcome.vue')
    },
    {
      path: '/home',
      name: '首页',
      component: () => import('../views/home.vue')
    },
    {
      path: '/app-home-page',
      name: '首页配置',
      component: () => import('../views/active/appHomePage.vue')
    },
    {
      path: '/login',
      name: '用户登录',
      component: () => import('../views/login.vue')
    },
    {
      path: '/orders',
      name: '订单管理',
      component: () => import('../views/orders/index.vue')
    },
    {
      path: '/download-center',
      name: '下载中心',
      component: () => import('../views/downloadCenter/index.vue')
    },
    {
      path: '/withdraw',
      name: '提现列表',
      component: () => import('../views/withdraw/index.vue')
    },
    {
      path: '/admin',
      name: '管理员列表',
      component: () => import('../views/admin/index.vue')
    },
    {
      path: '/bank-list',
      name: '银行列表',
      component: () => import('../views/admin/bankList.vue')
    },
    {
      path: '/users',
      name: '用户列表',
      component: () => import('../views/users/index.vue')
    },
    {
      path: '/goods-category',
      name: '商品分类',
      component: () => import('../views/goods/categoryList.vue')
    },
    {
      path: '/goods-list',
      name: '商品列表',
      component: () => import('../views/goods/goodsList.vue')
    },
    {
      path: '/goods-create',
      name: '创建基础商品',
      component: () => import('../views/goods/createOrUpdateGoods.vue')
    },
    {
      path: '/goods-edit',
      name: '更新基础商品',
      component: () => import('../views/goods/createOrUpdateGoods.vue')
    }
    ,
    {
      path: '/goods-spu-list',
      name: 'Spu商品列表',
      component: () => import('../views/goods/spuGoodsList.vue')
    }
    ,
    {
      path: '/spu-create',
      name: '创建spu',
      component: () => import('../views/goods/createOrUpdateSpu.vue')
    },
    {
      path: '/spu-edit',
      name: '编辑spu',
      component: () => import('../views/goods/createOrUpdateSpu.vue')
    },
    {
      path: '/menu-list',
      name: '菜单列表',
      component: () => import('../views/admin/menuList.vue')
    }
    ,
    {
      path: '/permission-group',
      name: '权限组列表',
      component: () => import('../views/admin/permissionGroup.vue')
    }
    ,
    {
      path: '/company',
      name: '分公司列表',
      component: () => import('../views/distributionSales/company.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.name
  next()
})

router.createRouter

export default router