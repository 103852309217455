import requestAxios from './request.js'

const api = {}

api.getOrderLists = params => requestAxios.request({
  url: '/orders/index',
  method: 'get',
  params
})

api.getDeliveryCompany = params => requestAxios.request({
  url: '/orders/get-delivery-company',
  method: 'get',
  params
})


api.postExportAdd = params => requestAxios.request({
  url: '/export/add',
  method: 'post',
  params
})

api.getExportLists = params => requestAxios.request({
  url: '/export/index',
  method: 'get',
  params
})

api.orderDelivery = params => requestAxios.request({
  url: '/orders/delivery',
  method: 'post',
  params
})

api.orderCancel = params => requestAxios.request({
  url: '/orders/cancel',
  method: 'post',
  params
})

api.orderRefund = params => requestAxios.request({
  url: '/orders/refund',
  method: 'post',
  params
})

api.getOrderCommision = params => requestAxios.request({
  url: '/orders/get-commision',
  method: 'get',
  params
})

api.getOrderDetail = params => requestAxios.request({
  url: '/orders/info',
  method: 'get',
  params
})

api.getOrderTrace = params => requestAxios.request({
  url: '/orders/get-order-trace',
  method: 'get',
  params
})

api.getWithdrawList = params => requestAxios.request({
  url: '/withdraw/index',
  method: 'get',
  params
})

api.passWithdraw = params => requestAxios.request({
  url: '/withdraw/pass',
  method: 'post',
  params
})

api.refuseWithdraw = params => requestAxios.request({
  url: '/withdraw/refuse',
  method: 'post',
  params
})

api.login = params => requestAxios.request({
  url: '/admin/login',
  method: 'post',
  params
})

api.getAdminIndex = params => requestAxios.request({
  url: '/admin/index',
  method: 'get',
  params
})

api.addAdmin = params => requestAxios.request({
  url: '/admin/add',
  method: 'post',
  params
})

api.editAdmin = params => requestAxios.request({
  url: '/admin/update',
  method: 'post',
  params
})

api.deleteAdmin = params => requestAxios.request({
  url: '/admin/delete',
  method: 'post',
  params
})

api.getUserLists = params => requestAxios.request({
  url: '/user/index',
  method: 'get',
  params
})

api.setUserVip = params => requestAxios.request({
  url: '/user/set-vip',
  method: 'post',
  params
})

api.getUserWalletInfo = params => requestAxios.request({
  url: '/user/get-wallet',
  method: 'get',
  params
})

api.getUserWalletLogs = params => requestAxios.request({
  url: '/user/get-wallet-logs',
  method: 'get',
  params
})

api.updateUserWallet = params => requestAxios.request({
  url: '/user/update-wallet',
  method: 'post',
  params
})

api.levelChange = params => requestAxios.request({
  url: '/user/set-level',
  method: 'post',
  params
})

api.userChangeParent = params => requestAxios.request({
  url: '/user/change-parent',
  method: 'post',
  params
})

api.bankList = params => requestAxios.request({
  url: '/bank/index',
  method: 'get',
  params
})

api.addBank = params => requestAxios.request({
  url: '/bank/add',
  method: 'post',
  params
})

api.editBank = params => requestAxios.request({
  url: '/bank/update',
  method: 'post',
  params
})

api.categoryList = params => requestAxios.request({
  url: '/goods-category/index',
  method: 'get',
  params
})

api.editCategory = params => requestAxios.request({
  url: '/goods-category/update',
  method: 'post',
  params
})

api.addCategory = params => requestAxios.request({
  url: '/goods-category/add',
  method: 'post',
  params
})

api.deleteCategory = params => requestAxios.request({
  url: '/goods-category/delete',
  method: 'post',
  params
})

api.addBaseSpu = params => requestAxios.request({
  url: '/goods-base-spu/add',
  method: 'post',
  params
})

api.updateBaseSpu = params => requestAxios.request({
  url: '/goods-base-spu/update',
  method: 'post',
  params
})

api.getBaseSpuList = params => requestAxios.request({
  url: '/goods-base-spu/index',
  method: 'get',
  params
})

api.getBaseSpuInfo = params => requestAxios.request({
  url: '/goods-base-spu/info',
  method: 'get',
  params
})

api.getSpuList = params => requestAxios.request({
  url: '/goods-spu/index',
  method: 'get',
  params
})

api.getSpuInfo = params => requestAxios.request({
  url: '/goods-spu/info',
  method: 'get',
  params
})

api.updateSpu = params => requestAxios.request({
  url: '/goods-spu/update',
  method: 'post',
  params
})

api.addSpu = params => requestAxios.request({
  url: '/goods-spu/add',
  method: 'post',
  params
})

api.addMenu = params => requestAxios.request({
  url: '/sys-menu/add',
  method: 'post',
  params
})

api.updateMenu = params => requestAxios.request({
  url: '/sys-menu/update',
  method: 'post',
  params
})

api.deleteMenu = params => requestAxios.request({
  url: '/sys-menu/delete',
  method: 'post',
  params
})

api.menuTree = params => requestAxios.request({
  url: '/sys-menu/get-menu-tree',
  method: 'get',
  params
})

api.menuInfo = params => requestAxios.request({
  url: '/sys-menu/info',
  method: 'get',
  params
})

api.groupList = params => requestAxios.request({
  url: '/sys-permission-group/index',
  method: 'get',
  params
})

api.createGroup = params => requestAxios.request({
  url: '/sys-permission-group/add',
  method: 'post',
  params
})

api.updateGroup = params => requestAxios.request({
  url: '/sys-permission-group/update',
  method: 'post',
  params
})

api.deleteGroup = params => requestAxios.request({
  url: '/sys-permission-group/delete',
  method: 'post',
  params
})

api.saveGroupPermission = params => requestAxios.request({
  url: '/sys-permission-group/save-menu',
  method: 'post',
  params
})

api.getAdminGroups = params => requestAxios.request({
  url: '/sys-permission-group/get-admin-groups',
  method: 'get',
  params
})

api.getUserData = params => requestAxios.request({
  url: '/data/get-user-data',
  method: 'get',
  params
})

api.getOrderData = params => requestAxios.request({
  url: '/data/get-order-data',
  method: 'get',
  params
})

api.getWithdrawData = params => requestAxios.request({
  url: '/data/get-withdrawal-data',
  method: 'get',
  params
})

api.getAdminMenu = params => requestAxios.request({
  url: '/sys-menu/get-login-menu',
  method: 'get',
  params
})

api.getCompanyList = params => requestAxios.request({
  url: '/company/index',
  method: 'get',
  params
})

api.updateCompany = params => requestAxios.request({
  url: '/company/update',
  method: 'post',
  params
})

export default api