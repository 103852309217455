export function setItemWithExpiry(key, value, ttl) {
	const item = {
		value: value,
		expiry: Date.now() + ttl // 设置过期时间为当前时间加上ttl（生存时间，单位毫秒）
	}
	localStorage.setItem(key, JSON.stringify(item))
}

// 获取存储项，如果已过期则返回null
export function getItemWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr);
	if (Date.now() > item.expiry) {
		// localStorage.removeItem(key)
		return {value: item.value, expire: true}
	}
	return {value: item.value, expire: false}
}

/**
 * 判断操作权限
 * @param {*} menu 
 * @param {*} operate 
 * @returns 
 */
export function checkPermission(module, operate) {
	let menus = getItemWithExpiry("userMenus")
	if (menus != null && menus.value != null) {
		return menus.value.permissions.includes(module + "@" + operate)
	}
	return false
}