<template>
  <div class="vab-avatar">
    <a-dropdown>
      <span class="ant-dropdown-link">
		{{ username }}
        <DownOutlined />
      </span>
      <template v-slot:overlay>
        <a-menu>
          <a-menu-item @click="logout">退出登录</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
  import { DownOutlined } from '@ant-design/icons-vue'
	import { reactive } from 'vue';
	import { useRouter } from 'vue-router';

  export default {
    name: '头部导航',
    components: { DownOutlined },
    setup() {
		const router = useRouter()
	  
		const logout = async () => {
			localStorage.clear()
			router.push('/login')
		}

		return {
			username: "管理员: " + localStorage.getItem("username"),
			logout
		}
    },
  }
</script>

<style lang="less">
  .vab-avatar {
	  float: right;
    .ant-dropdown-link {
      display: block;
      min-height: 64px;
      cursor: pointer;
	  margin-right: 20px;
    }
  }
</style>
