<template>
  <a-config-provider :locale="locale" :theme="{token: {colorPrimary: '#cfccf2'}}" v-if="state.isLogin">
    <a-layout style="min-height:100vh">
      <OsMenus />
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
			<osHeader />
        </a-layout-header>
        <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-config-provider>
  <router-view v-if="!state.isLogin"/>
</template>

<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import OsMenus from "@/components/osMenus.vue";
import osHeader from "@/components/header.vue";
import { useRoute } from 'vue-router';
import { reactive, watch } from 'vue';
const locale = zhCN
const route = useRoute();
const state = reactive({
  isLogin: true
})

watch(()=>{
  
  let path = route.path;
  if(path == '/login') {
    state.isLogin = false
  } else {
    state.isLogin = true
  }
})
</script>

<style>
.scenes-search-col {
  padding-right: 20px;
  padding-left: 20px;
}

.search-btn button {
  margin: 0 5px;
}

.pagination-block {
  margin: 5px;
  text-align: right;
}
</style>