<template>
	<a-layout-sider>
		<div class="logo">紫晶汇康</div>
		<a-menu v-model:selectedKeys="state.selectedKeys" v-model:openKeys="state.openKeys" theme="dark" mode="inline">

			<template v-for="item in state.menuItems">
				<template v-if="item.children.length == 0">
					<a-menu-item :key="item.key">
						<router-link :to="item.to">{{ item.title }}</router-link>
					</a-menu-item>
				</template>

				<template v-else>
					<a-sub-menu :key="item.key">
						<template #title>
							<span>
								<span>{{ item.title }}</span>
							</span>
						</template>
						<a-menu-item v-for="subItem in item.children" :key="subItem.key">
							<router-link :to="subItem.to">{{ subItem.title }}</router-link>
						</a-menu-item>
					</a-sub-menu>
				</template>
			</template>

		</a-menu>
	</a-layout-sider>
</template>

<script setup>
import { reactive, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import httpService from '@/common/api'
import {setItemWithExpiry, getItemWithExpiry} from '@/common/utils'
import { message } from 'ant-design-vue';

const route = useRoute();
const state = reactive({
	selectedKeys: [],
	openKeys: [],
	menuItems: [
		// { key: "home", title: "首页", to: "/", children: []},
		// {
		// 	key: "active-manage", title: "运营管理", children: [
		// 		{ key: "app-home-page", title: "首页配置", to: "/app-home-page" },
		// 	]
		// },
		// {
		// 	key: "user-manage", title: "用户管理", children: [
		// 		{ key: "users", title: "用户列表", to: "/users" },
		// 	]
		// },
		// {
		// 	key: "goods-manage", title: "商品管理", children: [
		// 		{ key: "goods-category", title: "商品分类", to: "/goods-category" },
		// 		{ key: "goods-list", title: "商品列表", to: "/goods-list" },
		// 		{ key: "goods-spu-list", title: "Spu列表", to: "/goods-spu-list" }
		// 	]
		// },
		// {
		// 	key: "order-manage", title: "订单管理", children: [
		// 		{ key: "orders", title: "订单列表", to: "/orders" }
		// 	]
		// },
		// {
		// 	key: "financial-manage", title: "财务管理", children: [
		// 		{ key: "withdraw", title: "提现列表", to: "/withdraw" }
		// 	]
		// },
		// {
		// 	key: "resource-manage", title: "资源管理", children: [
		// 		{ key: "download-center", title: "下载中心", to: "/download-center" }
		// 	]
		// },
		// {
		// 	key: "system-manage", title: "系统管理", children: [
		// 		{ key: "admin", title: "管理员列表", to: "/admin" },
		// 		{ key: "bank-list", title: "银行列表", to: "/bank-list" },
		// 		{ key: "menu-list", title: "菜单列表", to: "/menu-list" },
		// 		{ key: "permission-group", title: "权限组列表", to: "/permission-group" }
		// 	]
		// },
	],
	operateMaps: []
})

const findMenu = (key) => {
	for (let i = 0; i < state.menuItems.length; i++) {
		if (state.menuItems[i].key == key) {
			return state.menuItems[i].key
		}
		if (state.menuItems[i].children.length > 0) {
			for (let j = 0; j < state.menuItems[i].children.length; j++) {
				if (state.menuItems[i].children[j].key == key) {
					return state.menuItems[i].key
				}
			}
		}
	}
}

const getUserMenu = () => {
	let menus = getItemWithExpiry("userMenus")
	if (menus != null && !menus.expire) {
		state.menuItems = menus.value.menu
	} else {
		let menus = []
		let operateMaps = []
		let permissions = []
		httpService.getAdminMenu().then(res => {
			try {
				res.forEach(ele => {
					let children = []
					if (ele.children != undefined) {
						ele.children.forEach(ele1 => {
							// 保证二级配置的都是菜单
							if (ele1.web_route.startsWith("/")) {
								let routeKey = ele1.web_route.slice(1);
								children.push({ key: routeKey, to: ele1.web_route, title: ele1.menu_name })
								permissions.push(routeKey)

								// 保证三级菜单配置的都是按钮
								if (ele1.children != undefined) {
									ele1.children.forEach(ele2 => {
										if (ele2.web_route.startsWith("@")) {
											operateMaps.push({menu2: routeKey, menu1: ele.web_route.slice(1), page: ele2.web_route.slice(1)})
											permissions.push(routeKey + ele2.web_route)
										}
									})
								}
							}
						})
					}
					let routeKey1 = ele.web_route.slice(1)
					menus.push({ key:  routeKey1, to: ele.web_route, title: ele.menu_name, children: children })
					permissions.push(routeKey1)
				})
				// 优化网络请求, 10分钟更新一次菜单权限
				state.menuItems = menus
				state.operateMaps = operateMaps
				setItemWithExpiry("userMenus", {menu: menus, operate: operateMaps, permissions: permissions}, 600000)
			} catch (err) {

			}
		})
	}
}

watch(() => route.path, (newVal, oldVal) => {
	let menus = getItemWithExpiry("userMenus")
	if (menus != null && menus.expire) {
		getUserMenu()
	}
	let paths = newVal.split("/")
	let path = paths[paths.length - 1]
	let folder = ""
	if (menus != null && menus.value != null) {
		if (path == "" && menus.value.menu.length > 0) {
			window.location.href = "#" + menus.value.menu[0].children[0].to
		} else {
			let subPage = menus.value.operate.find(obj => obj.page == path)
			if (subPage != undefined) {
				path = subPage.menu2
				folder = subPage.menu1
			} else {
				if (!menus.value.permissions.includes(path)) {
					message.error("您没有权限执行此操作")
					window.location.href = '#/'
				}
			}
		}
	}
	if (folder == "") {
		folder = findMenu(path)
	}
	console.log("current path = " + path)
	state.selectedKeys = [path]
	state.openKeys = [folder]
})

onMounted(() => {
	getUserMenu()
})
</script>

<style>
.logo {
	color: #fff;
	font-size: 28px;
	text-align: center;
	padding: 30px 0;
}

.child-menu {
	display: block;
}
</style>